<!-- 排5个位走势图 -->
<template>
    <div class="">
        <div class="ssq-table">
            <div class="ssq-table-header">
                <div class="ssq-icon">
                    <svg t="1629609108732" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="2399" width="45" height="45">
                        <path d="M452.846933 52.986311h117.771378l-127.724089 392.436622h-117.543822z" fill="#322C2B"
                            p-id="2400"></path>
                        <path d="M29.641956 446.839467l36.189866-111.889067 333.969067 242.591289-36.420267 111.741155z"
                            fill="#0C7CBE" p-id="2401"></path>
                        <path d="M273.965511 971.013689l-95.274667-69.290667 333.7216-242.212978 95.379912 69.048889z"
                            fill="#EFB918" p-id="2402"></path>
                        <path d="M847.798044 900.616533l-95.277511 69.1456-127.374222-392.220444 95.152356-69.304889z"
                            fill="#14934A" p-id="2403"></path>
                        <path d="M958.196622 333.348978l36.161422 111.746844H581.808356l-36.411734-111.746844z"
                            fill="#D7282A" p-id="2404"></path>
                    </svg>
                    <div class="ssq-one">
                        <h2>中国体育彩票</h2>
                    </div>
                    <div class="ssq-two">
                        <h3>排 5 个 位 走 势 图</h3>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <table class="tab" border="1" cellspacing="0" cellpadding="0">
                <thead class="thead-fixed">
                    <tr>
                        <th rowspan="2" class="ranks_a">期号</th>
                        <th rowspan="2" class="ranks_a1">开奖</th>
                        <th colspan="10" class="ranks_b">排 5 个 位 走 势 图</th>
                        <th colspan="3" class="ranks_b">百位012路</th>
                        <th colspan="3" class="ranks_b">百位147路</th>
                        <th colspan="3" class="ranks_b">百位258路</th>
                        <th colspan="2" class="ranks_b">奇 偶 </th>
                        <th colspan="2" class="ranks_b"> 大 小 </th>
                        <th colspan="2" class="ranks_b">质 合</th>
                        <th colspan="10" class="ranks_b">百位振幅</th>
                        <th colspan="3" class="ranks_b">振幅012路</th>
                        <th colspan="3" class="ranks_b">振幅147路</th>
                        <th colspan="3" class="ranks_b">振幅258路</th>
                        <th colspan="2" class="ranks_b"> 奇 偶 </th>
                        <th colspan="2" class="ranks_b"> 大 小 </th>
                        <th colspan="2" class="ranks_b">质 合</th>
                        <th colspan="5" class="ranks_b">振幅五行走势图</th>
                    </tr>
                    <tr>
                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">3</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">6</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">9</th>
                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">奇</th>
                        <th class="ranks_c">偶</th>
                        <th class="ranks_c">大</th>
                        <th class="ranks_c">小</th>
                        <th class="ranks_c">质</th>
                        <th class="ranks_c">合</th>
                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">3</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">6</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">9</th>
                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">奇</th>
                        <th class="ranks_c">偶</th>
                        <th class="ranks_c">大</th>
                        <th class="ranks_c">小</th>
                        <th class="ranks_c">质</th>
                        <th class="ranks_c">合</th>
                        <th class="ranks_c">金</th>
                        <th class="ranks_c">木</th>
                        <th class="ranks_c">水</th>
                        <th class="ranks_c">火</th>
                        <th class="ranks_c">土</th>







                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item2 ,x) in pl35" :key='x'>
                        <td>{{item2.expect}}</td>
                        <td>
                            <div>
                                {{item2.red5[0] }}{{item2.red5[1] }}{{item2.red5[2] }}{{item2.red5[3] }}{{item2.red5[4]
                                }}
                            </div>

                        </td>
                        <!-- 尾数走势图 -->
                        <td class="b-red" v-for="(item ,i) in red" :key='i'>
                            <div :class=" item == (red0[x])%10 ? 'q-red':'' ">
                                {{ item ==(red0[x])%10 ? item : '' }}
                            </div>
                        </td>
                        <!-- 012路 -->
                        <td class="b-lan" v-for="(item ,i) in lye" :key='i'>
                            <div :class=" item == (red0[x])%10%3 ? 'q-lan':'' ">
                                {{ item ==red0[x]%10%3 ?item:'' }}
                            </div>
                        </td>
                        <!-- 147路 -->
                        <td class="b-red" v-for="(item ,i) in ysq" :key='i'>
                            <div :class=" item == (([1,5,6,9].indexOf(red0[x])==-1 ? '' : 1)||
                          ([0,2,4].indexOf(red0[x])==-1 ? '' : 4)||([3,7,8].indexOf(red0[x])==-1 ? '' : 7) )
                          ? 'q-red' : '' ">
                                {{ item == (([1,5,6,9].indexOf(red0[x])==-1 ? '' : 1)||
                                ([0,2,4].indexOf(red0[x])==-1 ? '' : 4)||([3,7,8].indexOf(red0[x])==-1 ? '' : 7) )
                                ? item : '' }}
                            </div>
                        </td>
                        <!-- 258路 -->
                        <td class="b-lan" v-for="(item ,i) in ewb" :key='i'>
                            <div :class="  item == (([2,7,9].indexOf(red0[x])==-1 ? '' : 2)||([0,3,5].indexOf(red0[x])==-1 ? '' :
                          5)||([1,4,6,8].indexOf(red0[x])==-1 ? '' : 8) ) ? 'q-lan' : '' ">
                                {{ item == (([2,7,9].indexOf(red0[x])==-1 ? '' : 2)||([0,3,5].indexOf(red0[x])==-1 ? ''
                                :
                                5)||([1,4,6,8].indexOf(red0[x])==-1 ? '' : 8) ) ? item : '' }}
                            </div>
                        </td>
                        <!-- 奇偶 -->
                        <td class="b-red" v-for="(item ,i) in jo" :key='i'>
                            <div :class=" item == (red0[x]%2==0 ? '偶':'奇')? 'q-red' : '' ">
                                {{ item == (red0[x]%2==0 ? '偶':'奇')? item : '' }}
                            </div>
                        </td>
                        <!-- 大小 -->
                        <td class="b-lan" v-for="(item ,i) in dx" :key='i'>
                            <div :class=" item == (4>=red0[x] ? '小':'大') ? 'q-lan' : '' ">
                                {{ item == (4>=red0[x] ? '小':'大') ? item : '' }}
                            </div>
                        </td>
                        <!-- 质合 -->
                        <td class="b-red" v-for="(item ,i) in zh" :key='i'>
                            <div :class=" item == ([1,2,3,5,7].indexOf(red0[x])==-1 ? '合' : '质') ? 'q-red' : '' ">
                                {{ item == ([1,2,3,5,7].indexOf(red0[x])==-1 ? '合' : '质') ? item : '' }}
                            </div>
                        </td>
                        <!-- 振幅 -->
                        <td class="b-lan" v-for="(item ,i) in zf" :key='i'>
                            <div :class=" item==zf2[x] ? 'q-lan' : '' ">
                                {{item==zf2[x] ? item : ''}}
                            </div>
                        </td>
                        <!-- 振幅012路 -->
                        <td class="b-red" v-for="(item ,i) in lye" :key='i'>
                            <div :class="item==zf2[x]%10%3 ? 'q-red' : '' ">
                                {{item==zf2[x]%10%3 ? item : ''}}
                            </div>
                        </td>
                        <!-- 振幅147路 -->
                        <td class="b-lan" v-for="(item ,i) in ysq" :key='i'>
                            <div :class=" item == (([1,5,6,9].indexOf(zf2[x])==-1 ? '' : 1)||([0,2,4].indexOf(zf2[x])==-1 ? '' :
                          4)||([3,7,8].indexOf(zf2[x])==-1 ? '' : 7) )? 'q-lan' : '' ">
                                {{ item == (([1,5,6,9].indexOf(zf2[x])==-1 ? '' : 1)||([0,2,4].indexOf(zf2[x])==-1 ? ''
                                :
                                4)||([3,7,8].indexOf(zf2[x])==-1 ? '' : 7) )? item : '' }}
                            </div>
                        </td>
                        <!-- 振幅258路 -->
                        <td class="b-red" v-for="(item ,i) in ewb" :key='i'>
                            <div :class=" item == (([2,7,9].indexOf(zf2[x])==-1 ? '' : 2)||([0,3,5].indexOf(zf2[x])==-1 ? '' :
                          5)||([1,4,6,8].indexOf(zf2[x])==-1 ? '' : 8) ) ? 'q-red' : '' ">
                                {{ item == (([2,7,9].indexOf(zf2[x])==-1 ? '' : 2)||([0,3,5].indexOf(zf2[x])==-1 ? '' :
                                5)||([1,4,6,8].indexOf(zf2[x])==-1 ? '' : 8) ) ? item : '' }}
                            </div>
                        </td>
                        <!-- 振幅奇偶 -->
                        <td class="b-lan" v-for="(item ,i) in jo" :key='i'>
                            <div :class="item ==(zf2[x]%10%2 == 0 ? '偶':'奇') ? 'q-lan' : '' ">
                                {{ item ==(zf2[x]%10%2 == 0 ? '偶':'奇') ? item : '' }}
                            </div>
                        </td>
                        <!-- 振幅大小 -->
                        <td class="b-red" v-for="(item ,i) in dx" :key='i'>
                            <div :class="item == (4>=zf2[x] ? '小':'大') ? 'q-red' : '' ">
                                {{ item == (4>=zf2[x] ? '小':'大') ? item : '' }}
                            </div>
                        </td>
                        <!-- 振幅质合 -->
                        <td class="b-lan" v-for="(item ,i) in zh" :key='i'>
                            <div :class=" item == ([1,2,3,5,7].indexOf(zf2[x])==-1 ? '合' : '质') ? 'q-lan' : '' ">
                                {{ item == ([1,2,3,5,7].indexOf(zf2[x])==-1 ? '合' : '质') ? item : '' }}
                            </div>
                        </td>
                        <!-- 五行走势图 -->
                        <td class="b-red" v-for="(item ,i) in wx" :key='i'>
                            <div
                                :class="  item ==(1==zf2[x]||9==zf2[x]? '金':''||2==zf2[x]||8==zf2[x]? '木':''||
                          3==zf2[x]||7==zf2[x]? '水':''|| 4==zf2[x]||6==zf2[x]?'火':''||0==zf2[x]||5==zf2[x]?'土':'')? 'q-red' : ''  ">
                                {{ item ==(1==zf2[x]||9==zf2[x]? '金':''||2==zf2[x]||8==zf2[x]? '木':''||
                                3==zf2[x]||7==zf2[x]? '水':''||
                                4==zf2[x]||6==zf2[x]?'火':''||0==zf2[x]||5==zf2[x]?'土':'')? item : '' }}
                            </div>
                        </td>


                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 56" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 56" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 56" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
     import mitt from '@/js/mitt.js'
    //import '../js/font.js' //js文件引入，注意路径
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    //import Introduction from '@/views/Introduction.vue'
    export default {
        name: '3d-bai',
        components: {

        },
        data() { // 数据源
            return {
                red: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
                red0: [],
                lye: [0, 1, 2],
                ysq: [1, 4, 7],
                ewb: [2, 5, 8],
                jo: ["奇", "偶"],
                dx: ['大', '小'],
                zh: ['质', '合'],
                zf: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
                zf2: [0],
                wx: ['金', '木', '水', '火', '土'],
            }
        },
        mounted() {
            this.mred()
            this.mzf()
            this.find()
        },
        computed: {
            pl35() {
                return this.$store.state.pl35;
            },
        },
        methods: { // 函数体部分，js的主要逻辑控制  
            find() {
                document.documentElement.scrollTop = 0;
                if (this.pl35 == '') {
                    mitt.emit('pl35');
                }
            }, 
            mred() {//尾数
                this.red0=[]
                for (var i = 0; i < this.pl35.length; i++) {
                    this.red0.push((this.pl35[i].red5[4]) % 10)
                }
            },

            mzf() {//振幅
                this.zf2=[0]
                var arr = new Array()
                for (var i = 0; i < this.red0.length - 1; i++) {
                    arr = this.red0[i] - this.red0[i + 1]
                    this.zf2.push(Math.abs(arr))
                }
             
            },


        },
        props: {

        },
        watch: {
            pl35() {
                this.mred()
                this.mzf()
            }
        }
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
    .ssq-table-header {
        width: 100%;
        height: 50px;
        background-color: rgb(224, 202, 5);
    }

    .tab {
        margin: 0 auto;
        border-collapse: collapse;
    }

    .thead-fixed th {
        border: 1px solid #808080;
    }

    .ranks_a1 {
        width: 40px;
    }

    .ranks_c {
        width: 23px;
        color: red;
    }

    .ranks_d {
        width: 22px;
        color: blue;
    }

    .ssq-icon {
        text-align: left;
    }

    .icon {
        float: left;
    }

    .ssq-one {
        float: left;
        height: 42px;
        line-height: 42px;
        color: #fff;

    }

    .ssq-two {
        float: left;
        width: 80%;
        text-align: center;
        line-height: 42px;
        font-size: 25px;
        color: rgb(252, 251, 249);
    }


    tbody .xuan {
        background-color: rgb(160, 156, 156);
        border-radius: 15px;
        color: rgb(253, 253, 253);
    }

    .b-red {
        background-color: rgb(191, 224, 191);
    }

    .b-lan {
        background-color: #f8fcb2;
    }


    .q-red {
        background-color: rgb(87, 28, 114);
        border-radius: 15px;
        color: #fff;
    }

    .q-lan {
        background-color: #548ce0;
        border-radius: 15px;
        color: #fff;
    }
</style>